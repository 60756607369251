<template>
  <div>
    <b-card v-if="azienda" :title="azienda.ragioneSociale">
      <!-- <b-card-text>{{azienda}}</b-card-text> -->
      <b-card-text>Questo è il link da condividere internamente per le segnalazioni anonime
        <b-input-group>
           <b-form-input :value="link_azienda" />
           <b-input-group-append>
             <b-button @click="copyLink()" variant="outline-primary">
               Copia
             </b-button>
           </b-input-group-append>
        </b-input-group>
        <!-- <b-link
          :href="link_azienda"
          target="_blank"
        >
          LINK
        </b-link> -->
        condividilo sul tuo sito web, con i colleghi della tua azienda e chiunque possa fare una segnalazione.
      </b-card-text>
    </b-card>

    <b-card-group v-if="azienda&&!filterBy" deck>
      <b-card @click="$router.push('/home/all')" class="stats-card" bg-variant="default" header="Totale Segnalazioni">
      <b-card-text style="color: #FF9F43;" class="text-right font-weight-bolder display-4">{{items.length}}</b-card-text> 
      </b-card>
     
      <b-card @click="$router.push('/home/open')" class="stats-card" bg-variant="default" header="Aperte">
        <b-card-text style="color: green;" class="text-right font-weight-bolder display-4">{{c_open}}</b-card-text>
      </b-card>

      <b-card @click="$router.push('/home/awaits')" class="stats-card" bg-variant="default" header="In attesa">
        <b-card-text style="color: red;"  class="text-right font-weight-bolder display-4">{{c_waiting}}</b-card-text>
      </b-card>

      <b-card @click="$router.push('/home/new')" class="stats-card" bg-variant="default" header="Nuove">
        <b-card-text style="color: blue;" class="text-right font-weight-bolder display-4">{{c_new}}</b-card-text>
      </b-card>

      <b-card @click="$router.push('/home/closed')" class="stats-card" bg-variant="default" header="Chiuse">
        <b-card-text style="color: gray-600;" class="text-right font-weight-bolder display-4">{{c_closed}}</b-card-text>
      </b-card>
    </b-card-group>
    
    <b-card-group v-if="azienda&&filterBy" style="width:20%">
      <b-card @click="$router.push('/home/all')" class="stats-card" bg-variant="default" header="Disattiva filtro">
        <b-card-text style="color: black" class="font-weight-bolder display-4">X</b-card-text>
      </b-card>
    </b-card-group>


  <!--
    <b-card v-if="azienda&&!filterBy" :title="'Statistiche'">
      <b-card-text>
        <b-row class="stat-head-row">
          <b-col class="pb-2">
            Totale Segnalazioni
          </b-col>
          <b-col class="pb-2">
            Aperte
          </b-col>
          <b-col class="pb-2">
            In attesa
          </b-col>
          <b-col class="pb-2">
            Nuove
          </b-col>
          <b-col class="pb-2">
            Chiuse
          </b-col>
        </b-row>
        <b-row class="stat-body-row">
          <b-col class="pb-2">
            <b-badge class="custom-badge" variant="primary">{{items.length}}</b-badge>
          </b-col>
          <b-col class="pb-2">
            <b-badge class="custom-badge"  variant="secondary">{{c_open}}</b-badge>
          </b-col>
          <b-col class="pb-2">
            <b-badge class="custom-badge"  variant="warning">{{c_waiting}}</b-badge>
          </b-col>
          <b-col class="pb-2">
            <b-badge class="custom-badge"  variant="success">{{c_new}}</b-badge>
          </b-col>
          <b-col class="pb-2">
            <b-badge class="custom-badge"  variant="danger">{{c_closed}}</b-badge>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    -->
  <br>
    <b-card :key="$route.name" class="table-container">
      <b-card-title>
        <b-row>
          <b-col class="pb-2">
            Segnalazioni
          </b-col>
          <b-col class="pb-2 text-right">
            <b-button href="#" :disabled="refreshing" :variant="`outline-${refreshing ? 'danger' : 'primary'}`" @click="getAllSegnalazioniAzienda()">
              <feather-icon
                icon="RefreshCwIcon"
                size="21"
              />
              Aggiorna
            </b-button>
          </b-col>
        </b-row>
      </b-card-title>
      <b-table
         small
         :fields="fields"
         :items="items"
         responsive="sm"
         selectable
         select-mode="single"
         @row-selected="onRowSelected"
         tbody-class="text-center"
       >
         <!-- A virtual column -->
         <!-- <template #cell(index)="data">
           {{ data.index + 1 }}
         </template> -->

         <!-- A custom formatted column --> 
         <template #cell(id)="data">
           {{ data.item.id }}
         </template>

         <!-- A custom formatted column -->
         <template #cell(notified)="data">
           <b-badge
             pill
             :class="{'text-secondary': !data.item.notified}"
             :variant="data.item.notified ? 'warning' : 'light'"
           >
            {{ data.item.notifiedAt ? new Date(data.item.notifiedAt).toLocaleString('it-IT', dateOptions) : 'Nessuna notifica' }}
           </b-badge>
           <!-- <b-progress
             :value="data.item.value"
             max="100"
             :variant="data.item.variant"
             striped
           /> -->
         </template>

         <template #cell(answerAt)="data">
           <b-badge
             pill
             :variant="data.item.answerAt ? 'warning' : 'secondary'"
           >
            {{ data.item.answerAt ? new Date(data.item.answerAt).toLocaleString('it-IT', dateOptions) : 'Nessuna risposta' }}
           </b-badge>
         </template>

         <template #cell(closedAt)="data">
           <b-badge
             pill
             :variant="data.item.closedAt ? 'success' : 'info'"
           >
             {{ data.item.closedAt ?
                `Chiusa: ${new Date(data.item.closedAt).toLocaleString('it-IT', dateOptions)}`
                :
                `Aperta: ${new Date(data.item.createdAt).toLocaleString('it-IT', dateOptions)}`
              }}
           </b-badge>
         </template>

         <template #cell(awaitAnswer)="data">
           <b-badge
              v-if="!data.item.awaitAnswer"
             pill
             :class="{'text-secondary': !data.item.closedAt}"
             :variant="data.item.closedAt ? 'success' : 'light'"
           >
             {{
               countClosedAt(data.item)
             }}
           </b-badge>
           <b-badge
              v-else-if="data.item.closedAt"
             pill
             :class="{'text-secondary': !data.item.closedAt}"
             variant="success"
           >
             {{
               'già chiusa'
             }}
           </b-badge>
           <b-badge
              v-else
             pill
             :variant="data.item.answerAt ? 'warning' : 'danger'"
           >
            <!-- countdown 7 or 90 gg -->
<!--
   PROBLEM:
   se utente anonimo inserisce risposta prima di azienda, answerAt esiste
   questo controllo dovrebbe essere su prima risposta di azienda e non su campo segnalazione answerAt
   creare computed che trova prima risposta con userUuid

   countDownAnswer da fare
-->
             {{
                data.item.answerAt ?
                countDownAnswer(data.item)
                :
                countDownFirstAnswer(data.item.createdAt)
              }}
      <!-- 'ancora X gg per risondere' -->
      <!-- 'in attesa (di aggiornamento da parte lesa)' -->
           </b-badge>
         </template>
       </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardTitle,
  BTable, BProgress, BBadge, BCard, BCardText, BLink, BCardGroup,
} from 'bootstrap-vue'

export default {
  data() {
    return {
      azienda: null,
      link_azienda: '',
      fields: [
        // 'index',
        {
          key: 'id',
          sortable: true,
          label: 'ID'
        },
        {
          key: 'title',
          sortable: true,
          label: 'Titolo',
          formatter: value => `${value.slice(0,20)}`
        },
        {
          key: 'notified',
          sortable: true,
          label: 'Ultima notifica',
        },
        {
          key: 'answerAt',
          sortable: true,
          label: 'Ultima Risposta',
          // formatter: value => `${value.first} ${value.last}`
        },
        {
          key: 'closedAt',
          sortable: true,
          label: 'Stato',
          formatter: value => `${value}`
        },
        {
          key: 'awaitAnswer',
          sortable: true,
          label: 'Scadenza',
        },
      ],
      items: [],
      selected: null,
      refreshing: false,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      c_new: 0,
      c_waiting: 0,
      c_open: 0,
      c_closed: 0,
    }
  },
  computed: {
    filterBy() {
      if (this.$route.params.filter) return this.$route.params.filter
      else {
        switch (this.$router.currentRoute.name) {
          case 'all-segnalazioni':
            return null
          case 'open-segnalazioni':
            return { closedAt: null }
          case 'new-segnalazioni':
            return { answerAt: null,closedAt: null }
          case 'awaits-segnalazioni':
            return { awaitAnswer: true,closedAt: null }
          case 'closed-segnalazioni':
            return { closed: true }
          default:
            return null
        }
      }
    },
    second() {
      return 1000
    },
    minute() {
      return this.second * 60
    },
    hour() {
      return this.minute * 60
    },
    day() {
      return this.hour * 24
    },
  },
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardTitle,
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardText,
    BLink,
    BCardGroup,
  },
  mounted() {
    this.getAzienda()
    this.getLinkAzienda()
    this.getAllSegnalazioniAzienda()
  },
  watch:{
    $route (to, from) {
      this.getAllSegnalazioniAzienda()
    }
  },
  methods: {
    getAzienda() {
      this.$http.get('/az/getDetails').then(res => {
        // console.log('AZIENDA ',res)
        this.azienda = res.data
      })
    },
    getAllSegnalazioniAzienda() {
      this.refreshing = true
      this.$http.post('/az/getAll', this.filterBy).then(res => {
        this.refreshing = false
        this.items = res.data
        this.c_closed = this.countClosed()
        this.c_new = this.countNew()
        this.c_open = this.countOpen()
        this.c_waiting = this.countWaiting()
      })
    },
    getLinkAzienda() {
      this.$http.get('/az/link').then(res => {
        this.link_azienda = res.data
      })
    },
    onRowSelected(items) {
      if (items && items.length) {
        this.$router.push({ name: 'report', params: { uuid: items[0].uuid } })
        // this.getOneSegnalazioneAzienda(items[0].uuid)
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.link_azienda);
      return true
    },
    // convert belows as computed
    countClosedAt(item) {
      if (!item.closedAt) return 'nessuna scadenza'
      const closeTime = new Date(item.closedAt)
      let firstAzAnswer = null
      item.contenutos.every( el => {
        // continue by returning true
        if (!el.userUuid) return true
        else {
          firstAzAnswer = el
          // find first azienda answer and stop by returning false
          return false
        }
      })
      if (!firstAzAnswer) return ''
      const answerTime = new Date(firstAzAnswer.createdAt)
      const diffTime = Math.abs(closeTime - answerTime);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays == 1) return 'chiusa '+diffDays+' giorno dalla prima risposta'
      return 'chiusa '+diffDays+' giorni dalla prima risposta'
    },
    // https://stackabuse.com/how-to-build-a-date-countdown-with-vanilla-javascript/
    countDownFirstAnswer(date) {
      const now = new Date();
      const expire = new Date(date);
      expire.setDate(expire.getDate() + 7);
      let answer = ''
      if (expire < now) {
        answer = 'SCADUTA'
        const diffTime = Math.abs(now - expire);
        const days = Math.floor(diffTime / this.day)
        const hours = Math.floor((diffTime % this.day) / this.hour)
        // const minutes = Math.floor((diffTime % this.hour) / this.minute)
        // const seconds = Math.floor((diffTime % this.minute) / this.second)
        return answer+' da '+days+' giorni e '+hours+' ore'
      } else {
        answer = 'rispondi entro'
        const diffTime = Math.abs(expire - now);
        const days = Math.floor(diffTime / this.day)
        const hours = Math.floor((diffTime % this.day) / this.hour)
        // const minutes = Math.floor((diffTime % this.hour) / this.minute)
        // const seconds = Math.floor((diffTime % this.minute) / this.second)
        return answer+' '+days+' giorni e '+hours+' ore'
      }
    },
    countDownAnswer(item) {
      const answers = item.contenutos
      if (!answers.length) return ''
      else {
        let byAzienda = answers.filter( el => el.userUuid )
        if (byAzienda.length > 1) return 'COMPLETED'
        else if (byAzienda[0] == answers[answers.length-1]) return 'in attesa di richiedente'
        else {
          const now = new Date();
          const expire = new Date(answers[answers.length-1].createdAt);
          expire.setDate(expire.getDate() + 90);
          let answer = ''
          if (expire < now) {
            answer = 'SCADUTA'
            const diffTime = Math.abs(now - expire);
            const days = Math.floor(diffTime / this.day)
            const hours = Math.floor((diffTime % this.day) / this.hour)
            // const minutes = Math.floor((diffTime % this.hour) / this.minute)
            // const seconds = Math.floor((diffTime % this.minute) / this.second)
            return answer+' da '+days+' giorni e '+hours+' ore'
          } else {
            answer = 'rispondi entro'
            const diffTime = Math.abs(expire - now);
            const days = Math.floor(diffTime / this.day)
            const hours = Math.floor((diffTime % this.day) / this.hour)
            // const minutes = Math.floor((diffTime % this.hour) / this.minute)
            // const seconds = Math.floor((diffTime % this.minute) / this.second)
            return answer+' '+days+' giorni e '+hours+' ore'
          }
        }
        return 'here'
      }
    },
    countOpen()
    {
      var count = 0;
      this.items.forEach(function(item) {
        if(item.closedAt==null)
        {
          count++;
        }
      });
      return count;
    },
    countClosed()
    {
      var count = 0;
      this.items.forEach(function(item) {
        if(item.closedAt!=null)
        {
          count++;
        }
      });
      return count;
    },
    countWaiting()
    {
      var count = 0;
      this.items.forEach(function(item) {
        if(item.awaitAnswer==true&&item.closedAt==null)
        {
          console.log(item)
          count++;
        }
      });
      return count;
    },
    countNew()
    {
      var count = 0;
      this.items.forEach(function(item) {
        if(item.answerAt==null&&item.closedAt==null)
        {
          count++;
        }
      });
      return count;
    },
  },
}
</script>

<style>
  .table-container tbody tr {
    height: 3rem;
  }
  
  .stats-card{
    font-size: large;
    font-weight: bold;
    text-align: right;
  }
  .stats-card:hover {
  cursor: pointer;
}
</style>
